import React from 'react';

const DashboardHome: React.FC = () => {
  return (
    <div>
      <h2>Dashboard Home</h2>
      <p>Welcome to your dashboard overview.</p>
    </div>
  );
};

export default DashboardHome; 