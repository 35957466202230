import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import { ToastProvider } from "./contexts/ToastContext";
import { ErrorBoundary, Loading } from "./components";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import About from "./pages/About";
import Insights from "./pages/Insights";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
// New dashboard subpage imports
import DashboardHome from "./pages/dashboard/DashboardHome";
import FinancialModels from "./pages/dashboard/FinancialModels";
import EquityAnalytics from "./pages/dashboard/EquityAnalytics";
import DocumentManagement from "./pages/dashboard/DocumentManagement";
import ResearchHub from "./pages/dashboard/ResearchHub";
import Collaboration from "./pages/dashboard/Collaboration";
import "./styles/shared.css";
import "./App.css";

// Create a client for React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <BrowserRouter>
              <Navigation />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/insights" element={<Insights />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route index element={<DashboardHome />} />
                  <Route path="financial-models" element={<FinancialModels />} />
                  <Route path="equity-analytics" element={<EquityAnalytics />} />
                  <Route path="document-management" element={<DocumentManagement />} />
                  <Route path="research-hub" element={<ResearchHub />} />
                  <Route path="collaboration" element={<Collaboration />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
