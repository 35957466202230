type LogLevel = "debug" | "info" | "warn" | "error";

interface LogEntry {
  timestamp: string;
  level: LogLevel;
  message: string;
  data?: Record<string, unknown>;
  error?: Error;
}

class Logger {
  private static instance: Logger;
  private logs: LogEntry[] = [];
  private readonly maxLogs = 1000;
  private readonly isDevelopment = process.env.NODE_ENV === "development";

  private constructor() {
    // Initialize any required resources
    if (this.isDevelopment) {
      console.log("Logger initialized in development mode");
    }
  }

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  private createLogEntry(
    level: LogLevel,
    message: string,
    data?: Record<string, unknown>,
    error?: Error,
  ): LogEntry {
    return {
      timestamp: new Date().toISOString(),
      level,
      message,
      data,
      error,
    };
  }

  private log(entry: LogEntry) {
    // Store log
    this.logs.unshift(entry);
    if (this.logs.length > this.maxLogs) {
      this.logs.pop();
    }

    // Console output in development
    if (this.isDevelopment) {
      const consoleMethod =
        entry.level === "error"
          ? "error"
          : entry.level === "warn"
            ? "warn"
            : entry.level === "info"
              ? "info"
              : "debug";

      console[consoleMethod](
        `[${entry.timestamp}] ${entry.level.toUpperCase()}: ${entry.message}`,
        entry.data ? { data: entry.data } : "",
        entry.error ? { error: entry.error } : "",
      );
    }

    // Send to error tracking service in production
    if (entry.level === "error" && !this.isDevelopment) {
      this.sendToErrorTracking(entry);
    }
  }

  private sendToErrorTracking(_entry: LogEntry) {
    // Implement your error tracking service here (e.g., Sentry)
    // Example:
    // Sentry.captureException(entry.error, {
    //   level: entry.level,
    //   extra: {
    //     message: entry.message,
    //     data: entry.data,
    //     timestamp: entry.timestamp,
    //   },
    // });
  }

  debug(message: string, data?: Record<string, unknown>) {
    this.log(this.createLogEntry("debug", message, data));
  }

  info(message: string, data?: Record<string, unknown>) {
    this.log(this.createLogEntry("info", message, data));
  }

  warn(message: string, data?: Record<string, unknown>) {
    this.log(this.createLogEntry("warn", message, data));
  }

  error(message: string, error?: Error, data?: Record<string, unknown>) {
    this.log(this.createLogEntry("error", message, data, error));
  }

  getLogs(level?: LogLevel): LogEntry[] {
    return level ? this.logs.filter((log) => log.level === level) : this.logs;
  }

  clearLogs() {
    this.logs = [];
  }
}

// Create and export a singleton instance as a named export
export const logger = Logger.getInstance();
