import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const Dashboard: React.FC = () => {
  return (
    <div>
      <h1>Dashboard</h1>
      <nav>
        <ul>
          <li><Link to="">Home</Link></li>
          <li><Link to="financial-models">Financial Models</Link></li>
          <li><Link to="equity-analytics">Equity Analytics</Link></li>
          <li><Link to="document-management">Document Management</Link></li>
          <li><Link to="research-hub">Research Hub</Link></li>
          <li><Link to="collaboration">Collaboration</Link></li>
        </ul>
      </nav>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard; 