import React from 'react';

const About: React.FC = () => {
  return (
    <div>
      <h1>About BlackRidge Group</h1>
      <p>We are experts in investment banking and legal advisory, committed to delivering advanced financial tools and research insights.</p>
    </div>
  );
};

export default About; 