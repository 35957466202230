import React from "react";
import { BaseComponentProps } from "../../types";
import "../../styles/shared.css";

export interface ButtonProps extends BaseComponentProps {
  variant?: "primary" | "secondary" | "outline";
  size?: "small" | "medium" | "large";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  fullWidth?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  className = "",
  style,
  variant = "primary",
  size = "medium",
  onClick,
  type = "button",
  disabled = false,
  loading = false,
  icon,
  fullWidth = false,
}) => {
  const buttonClasses = [
    "btn",
    `btn-${variant}`,
    `btn-${size}`,
    fullWidth ? "btn-full-width" : "",
    loading ? "btn-loading" : "",
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      className={buttonClasses}
      style={style}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
    >
      {loading ? (
        <span className="btn-spinner" />
      ) : (
        <>
          {icon && <span className="btn-icon">{icon}</span>}
          {children}
        </>
      )}
    </button>
  );
};

const Button = React.memo(ButtonComponent);
Button.displayName = "Button";
export default Button;
