import React from 'react';

const Home: React.FC = () => {
  return (
    <div>
      <h1>Welcome to BlackRidge Group</h1>
      <p>This is the landing page for our professional hub and marketing site.</p>
    </div>
  );
};

export default Home; 