import React from 'react';

const ResearchHub: React.FC = () => {
  return (
    <div>
      <h2>Research Hub</h2>
      <p>This page aggregates external research, market data, and provides direct links to resources including Damodaran's research.</p>
    </div>
  );
};

export default ResearchHub; 