import React from "react";
import { BaseComponentProps } from "../../types";
import styles from "./FormInput.module.css";

export interface FormInputProps extends BaseComponentProps {
  type?: "text" | "email" | "password" | "textarea";
  name: string;
  label: string;
  value: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FormInputComponent: React.FC<FormInputProps> = ({
  className = "",
  style,
  type = "text",
  name,
  label,
  value,
  error,
  placeholder,
  required = false,
  disabled = false,
  onChange,
  onBlur,
}) => {
  const inputClasses = [styles.input, error ? styles.error : "", className]
    .filter(Boolean)
    .join(" ");

  const renderInput = () => {
    const commonProps = {
      id: name,
      name,
      value,
      onChange,
      onBlur,
      placeholder,
      required,
      disabled,
      className: inputClasses,
      style,
    };

    if (type === "textarea") {
      return <textarea {...commonProps} />;
    }
    return <input type={type} {...commonProps} />;
  };

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      {renderInput()}
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

const FormInput = React.memo(FormInputComponent);
FormInput.displayName = "FormInput";
export default FormInput;
