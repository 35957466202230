import React from 'react';

const DocumentManagement: React.FC = () => {
  return (
    <div>
      <h2>Document Management & OCR</h2>
      <p>This page will help you manage documents and perform OCR processing to extract both qualitative and quantitative data.</p>
    </div>
  );
};

export default DocumentManagement; 