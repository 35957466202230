import logo from "../assets/images/logo512.png";
import finlawPic from "../assets/images/FinLawPicPanama.png";

export const images = {
  logo,
  finlaw: finlawPic,
  placeholder: "https://via.placeholder.com/400x300",
} as const;

export type ImageKey = keyof typeof images;
